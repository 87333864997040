import { ChangeEvent, Dispatch, SetStateAction } from "react";

interface IBigInput {
  placeholder?: string;
  label?: string;
  hint?: string;
  value?: string;
  onChange: Dispatch<SetStateAction<string>>;
  type?: "text" | "password"
}

function BigInput({
  placeholder,
  label,
  hint,
  value,
  onChange,
  type = "text"
}: IBigInput) {

  function handleChange(e: ChangeEvent<HTMLInputElement>){
    onChange(e.target.value);
  }

  return (
    <div className="Vlt-form__element Vlt-form__element--big">
      <div className="Vlt-input">
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <label>{label}</label>
      </div>
      {
        (!!hint) && (
          <small className="Vlt-form__element__hint">
            {hint}
          </small>
        )
      }
    </div>
  )
}
export default BigInput;