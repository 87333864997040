import React from "react";
import voltaIcons from "@vonagevolta/volta2/dist/symbol/volta-icons.svg";

import { useLocation, useHistory } from "react-router-dom";
import { useSession } from "components/SessionProvider"

import CompanyLogo from "./CompanyLogo";
import Greetings from "./Greetings";
import SingleMenu from "./SingleMenu";

function SideNavigation(){
  const [ menuActive, setMenuActive ] = React.useState("");
  const { signout } = useSession();
  const { pathname } = useLocation();
  const { push } = useHistory();

  function handleSignOutClick() {
    signout();
    push("/login");
  }

  React.useEffect(() => {
    // This part of code is for selection logic
    if(pathname === "/" || pathname.includes("/dashboard")) setMenuActive("dashboard");
    else if(pathname.includes("/numbers")) setMenuActive("numbers");
    else if(pathname.includes("/sdrs")) setMenuActive("sdrs");
    else if(pathname.includes("/routes")) setMenuActive("routes");
  }, [pathname])

  return (
    <React.Fragment>
      <header className="Vlt-sidenav__mobile Vlt-sidenav__mobile--dark">
        <button id="Vlt-sidenav-mobile-trigger">
          <svg className="Vlt-sidenav__collapse__close">
            <use xlinkHref={`${voltaIcons}#Vlt-icon-menu`}/>
          </svg>
        </button>
      </header>

      <div id="Vlt-sidenav" className="Vlt-sidenav Vlt-sidenav--rounded Vlt-sidenav--dark">
        <CompanyLogo/>
        <Greetings/>

        <div className="Vlt-sidenav__scroll">
          <ul className="Vlt-sidemenu">
            {/* <SingleMenu icon="Vlt-icon-pie-chart" label="Dashboard" active={menuActive === "dashboard"} to="/dashboard" /> */}
            <SingleMenu icon="Vlt-icon-phone-number" label="Numbers" active={menuActive === "numbers"} to="/numbers" />
            <SingleMenu icon="Vlt-icon-group-2-full" label="SDRs" active={menuActive === "sdrs"} to="/sdrs" />
            <SingleMenu icon="Vlt-icon-merge-2" label="Routes" active={menuActive === "routes"} to="/routes" />
          </ul>
        </div>

        <div className="Vlt-sidenav__block Vlt-sidenav__block--link Vlt-sidenav__block--border-top">
          <ul className="Vlt-sidemenu">
            <SingleMenu icon="Vlt-icon-quit" label="Sign Out" onClick={handleSignOutClick} />
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SideNavigation;