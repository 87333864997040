import useStyles from "./styles";

import Modal from "components/Modal";
import Spinner from "components/Spinner";

interface ILoadingModal {
  visible: boolean;
  label?: string;
}

function LoadingModal({ visible, label }: ILoadingModal) {
  const classes = useStyles();

  return (
    <Modal size="small" visible={visible}>
      <Modal.Content className={[ classes.content ]}>
        <Spinner white={false} />
        <h4>{label}</h4>
      </Modal.Content>
    </Modal>
  )
}
export default LoadingModal;