import React from "react";
import FetchHelper from "helper/fetch";
import useSDR from "../../hooks/sdr";

import Empty from "components/Empty";
import FullPageSpinner from "components/FullPageSpinner";
import NormalTable from "./NormalTable";

function SDRTable(){
  const [ isFetching, setIsFetching ] = React.useState(true);
  const mSDR = useSDR();

  React.useEffect(() => {
    FetchHelper.fetch(mSDR.fetch, setIsFetching);
  }, [ mSDR.fetch ])

  if(isFetching) return <FullPageSpinner />
  else if(mSDR.sdrs?.length <= 0) return <Empty />;
  else return <NormalTable sdrs={mSDR.sdrs}  />
}
export default SDRTable;