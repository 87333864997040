import React from "react";

import RouteProvider from "./contexts/route";
import RouteInformationCard from "./components/RouteInformationCard";
import RouteTable from "./components/RouteTable";
import Header from "./components/Header";

function RoutePage(){
  return (
    <RouteProvider>
      <Header />
      <hr />
      <div className="Vlt-grid">
        <div className="vlt-col Vlt-col--2of3">
          <RouteTable />
        </div>
        <div className="Vlt-col">
          <RouteInformationCard />
        </div>
      </div>
    </RouteProvider>
  )
}
export default RoutePage;