import clsx from "clsx";
import useStyles from "./styles";

interface ITableBodyRow {
  className?: string,
  children: any
}

function TableBodyRow({ className, children }: ITableBodyRow) {
  const mStyles = useStyles();

  return (
    <tr 
      className={clsx(
        mStyles.tableRow,
        className
      )}
    >
      {children}
    </tr>
  )
}
  
export default TableBodyRow;