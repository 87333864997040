import React from "react";
import vonageLogo from "@vonagevolta/volta2/images/logos/Vonage-api-dashboard-vert--white.svg";

function CompanyLogo(){
  return (
    <div className="Vlt-sidenav__block Vlt-sidenav__block--logo">
      <a className="Vlt-sidenav__logo" href="/">
        <img 
          className="Vlt-sidenav__elem--full" 
          alt="Logo"
          style={{ maxHeight: 50 }} 
          src={vonageLogo}
        />
      </a>
    </div>
  )
}
export default CompanyLogo