interface IModalFooter {
  children: any;
}

function ModalFooter({ children }: IModalFooter) {
  return (
    <div className="Vlt-modal__footer">
      {children}
    </div>
  )
}
export default ModalFooter;