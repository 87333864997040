import clsx from "clsx";
import AddButton from "./components/AddButton";

interface IButton {
  children: any;
  type: "primary" | "tertiary" | "secondary";
  onClick?: () => void;
  disabled?: boolean;
  className?: any;
  app?: boolean;
  buttonType?: "button" | "submit",
  style?: any;
}

function Button({ 
  children, 
  type, 
  onClick, 
  disabled,
  className,
  app,
  buttonType="button", 
  style={}
}: IButton) {
  return (
    <button 
      type={buttonType}
      className={clsx(
        "Vlt-btn", 
        `Vlt-btn--${type}`,
        (app)? "Vlt-btn--app": "",
        className
      )} 
      onClick={onClick} 
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  )
}

Button.Add = AddButton;

export default Button;