import React from "react";
import FetchHelper from "helper/fetch";
import useRoute from "../../hooks/route";

import NormalTable from "./NormalTable";
import Empty from "components/Empty";
import FullPageSpinner from "components/FullPageSpinner";

function RouteTable(){
  const [ isFetching, setIsFetching ] = React.useState(true);
  const mRoute = useRoute();

  React.useEffect(() => {
    FetchHelper.fetch(mRoute.fetch, setIsFetching);
  }, [ mRoute.fetch ])
  
  if(isFetching) return <FullPageSpinner />
  else if(mRoute.routes.length <= 0) return <Empty />;
  else return <NormalTable />
}
export default RouteTable;