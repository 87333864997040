import SDR from "entities/sdr";

interface State {
  number?: string
  searchText: string;
  isLoading: boolean;
  sdrs: SDR[];
}

type InitValue = {
  number: string,
  sdrs: SDR[]
}

type Action = 
  | { type: "INIT", value: InitValue }
  | { type: "CHANGE_SEARCH_TEXT", value: string }
  | { type: "CLEAR_SEARCH_TEXT" }
  | { type: "ADD_SDR", value: SDR[] }
  | { type: "REMOVE_SDR", value: SDR }
  | { type: "START_LOADING" }
  | { type: "STOP_LOADING" }
  | { type: "CLEAR_INPUT" }

export const initialState: State = {
  number: undefined,
  searchText: "",
  isLoading: false, 
  sdrs: []
}

export default function reducer(state: State, action: Action): State {
  switch(action.type){
    case "INIT":
      return Object.assign({}, state, {
        number: action.value.number,
        sdrs: action.value.sdrs
      })
    case "CHANGE_SEARCH_TEXT":
      return Object.assign({}, state, { searchText: action.value });
    case "CLEAR_SEARCH_TEXT":
      return Object.assign({}, state, { searchText: "" });
    case "ADD_SDR":
      return Object.assign({}, state, { sdrs: [
        ...state.sdrs,
        action.value
      ]})
    case "REMOVE_SDR":
      return Object.assign({}, state, { sdrs: state.sdrs.filter((sdr) => {
        return sdr.id !== action.value.id
      })})
    case "START_LOADING":
      return Object.assign({}, state, { isLoading: true });
    case "STOP_LOADING":
      return Object.assign({}, state, { isLoading: false })
    case "CLEAR_INPUT":
      return initialState;
    default:
      throw new Error("Ops! something went wrong");
  }
}