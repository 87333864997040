import React from "react";

import NumberProvider from "./contexts/number";
import HolidayProvider from "./contexts/holiday";
import Main from "./components/Main"

function NumberDetailPage(){
  return (
    <NumberProvider>
      <HolidayProvider>
        <Main />
      </HolidayProvider>
    </NumberProvider>
  )
}

export default NumberDetailPage;
