import SuccessMessage from "../models/success-message";
import { createContext } from "react";

type ErrorContextType = {
  throwError: (err: Error) => void;
  clearError: () => void;
  throwSuccess: (msg: SuccessMessage) => void;
}

export const ErrorContext = createContext<ErrorContextType>({
  throwError: (err: Error) => {},
  clearError: () => {},
  throwSuccess: (msg: SuccessMessage) => {}
});
