import SDR from "entities/sdr";
import FetchService from "services/fetch";
import queryString from "query-string";
import api from "configs/api";

class SDRService{
  static async search(sdr){
    const query = queryString.stringify({ name: sdr.name });
    const url = `${api.url}/sdrs/search?${query}`;
    const { result: responses } = await FetchService.post(url);
    const sdrs = responses.map((sdr) => SDR.fromResponse(sdr));
    return sdrs;
  }
}
export default SDRService;