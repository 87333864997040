import NumberService from "services/number";
import PhoneNumber from "entities/number";

import { useState } from "react";
import { useError } from "components/ErrorProvider";

import ButtonIcon from "components/ButtonIcon";
import LoadingModal from "components/LoadingModal";
import CustomError from "entities/error";

function DeleteButton({ number, onDeleted }: { number: PhoneNumber, onDeleted?: () => void }){
  const [isDeleting, setIsDeleting] = useState(false);
  const { throwError } = useError();

  async function handleClick(){
    try {
      setIsDeleting(true);
      if (!number.id) throw new CustomError("DeleteButton/handleClick", "System issue. Please contact developer");
      
      await NumberService.destroy(number.id);
      if (onDeleted) onDeleted();
    } catch (err) {
      if (throwError) throwError(err);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <ButtonIcon 
        type="destructive"
        icon="Vlt-icon-bin"
        onClick={handleClick}
      />
      <LoadingModal visible={isDeleting} label="Deleting..." />
    </>
  )
}
export default DeleteButton;