import React from "react";
import LuxonUtils from "@date-io/luxon";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import SideNavigation from "components/SideNavigation";
import PageContainer from "components/PageContainer";
import SectionContainer from "components/SectionContainer";
import PrivateRoute from "components/PrivateRoute";

import ErrorProvider from "components/ErrorProvider";
import SessionProvider from "components/SessionProvider";

import HomePage from "pages/HomePage";
import SDRPage from "pages/SDRPage";
import RoutePage from "pages/RoutePage";
import LoginPage from "pages/LoginPage";

import NumberPage from "pages/NumberPage";
import NumberDetailPage from "pages/NumberDetailPage";

function App(){
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <ErrorProvider>
        <Router>
          <SessionProvider>
            <PageContainer>
              <SideNavigation />
              <SectionContainer>
                <Switch>
                  <Route path="/login" component={LoginPage} exact />
                  <PrivateRoute path="/" exact>
                    <HomePage />
                  </PrivateRoute>
                  <PrivateRoute path="/numbers" exact>
                    <NumberPage />
                  </PrivateRoute>
                  <PrivateRoute path="/numbers/:numberId" exact>
                    <NumberDetailPage />
                  </PrivateRoute>
                  <PrivateRoute path="/sdrs" exact>
                    <SDRPage />
                  </PrivateRoute>
                  <PrivateRoute path="/routes" exact>
                    <RoutePage />
                  </PrivateRoute>
                </Switch>
              </SectionContainer>
            </PageContainer>
          </SessionProvider>
        </Router>
      </ErrorProvider>
    </MuiPickersUtilsProvider>
  );
}
export default App;