import PhoneNumber from "entities/number";
import countryLookup from "country-code-lookup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import useStyles from "./styles";

import NumberIndicator from "components/NumberIndicator";
import FlagIcon from "components/FlagIcon";
import Table from "components/Table";

import DetailColumn from "../DetailColumn";

interface INormalTable {
  numbers: PhoneNumber[],
  limit?: number;
  onUpdated?: () => void;
  onDeleted?: () => void;
}

function NormalTable({ onUpdated, onDeleted, numbers, limit=10 }: INormalTable){
  const mStyles = useStyles();

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header />
          <Table.Header>LOCATION</Table.Header>
          <Table.Header>NUMBER</Table.Header>
          <Table.Header />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {numbers.map((number, index) => {
          const phoneNumber = parsePhoneNumberFromString(`+${number.number}`);
          const country = countryLookup.byIso(phoneNumber?.country ?? "AX");
          const countryName = country.country.toLowerCase().replace(/\s/, "-");
          return (
            <Table.BodyRow key={number.id}>
              <Table.Column>
                <NumberIndicator number={(index + 1)} />
              </Table.Column>
              <Table.Column>
                <p><b>{number.geoName}</b></p>
                <p className="Vlt-grey">{number.geoCode}</p>
              </Table.Column>
              <Table.Column>
                <FlagIcon className={mStyles.flagIcon} icon={countryName} />
                {phoneNumber?.formatInternational() ?? "" }
              </Table.Column>
              <DetailColumn
                number={number}
                onUpdated={onUpdated}
                onDeleted={onDeleted}
              />
            </Table.BodyRow>
          )
        })}
      </Table.Body>
    </Table>
  )
}
export default NormalTable;