import React from "react";

import SDRProvider from "./contexts/sdr";
import SDRTable from "./components/SDRTable";
import SDRInformationCard from "./components/SDRInformationCard";
import Header from "./components/Header";

function SDRPage(){
  return (
    <SDRProvider>
      <Header />
      <hr />
      <div className="Vlt-grid">
        <div className="vlt-col Vlt-col--2of3">
          <SDRTable />
        </div>
        <div className="Vlt-col">
          <SDRInformationCard />
        </div>
      </div>
    </SDRProvider>
  )
}
export default SDRPage;