class TokenService{
  static retrieve(type = "access_token"){
    return localStorage.getItem(type) ?? undefined
  }

  static save(type, value){
    localStorage.setItem(type, value);
  }

  static clear(){
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
}
export default TokenService;