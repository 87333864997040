import api from "configs/api";
import FetchService from "services/fetch";
import Holiday from "../entities/holiday";

import { createContext } from "react";
import { useState, useCallback } from "react";
import { HolidayContextValues, HolidayProviderProps, CreateOptions, DestroyOptions, FetchOptions } from "../types";


export const HolidayContext = createContext<Partial<HolidayContextValues>>({
  fetch: () => Promise.resolve(),
  create: () => Promise.resolve(),
  destroy: () => Promise.resolve(),
  holidays: []
});

export default function HolidayProvider({ children }: HolidayProviderProps){
  const [holidays, setHolidays] = useState([]);

  const fetch = useCallback(
    async ({ numberID }: FetchOptions) => {
      const url = `${api.url}/numbers/${numberID}/holidays`;
      const { results: responses } = await FetchService.get(url);
      const holidays = responses.map((response: any) => Holiday.fromResponse(response))
      setHolidays(holidays);
    },
    []
  );

  async function create({ numberID, holiday }: CreateOptions) {
    const url = `${api.url}/numbers/${numberID}/holidays`;
    const body = JSON.stringify(holiday.toRequest());
    await FetchService.post(url, body);
    await fetch({ numberID });
  }

  async function destroy({ numberID, holidayID }: DestroyOptions) {
    const url = `${api.url}/numbers/${numberID}/holidays/${holidayID}`;
    await FetchService.delete(url);
    await fetch({ numberID });
  }

  return (
    <HolidayContext.Provider
      value={{
      fetch,
      create,
      destroy,
      holidays
      }}
    >
      {children}
    </HolidayContext.Provider>
  )
}