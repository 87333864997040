import React from "react";
import VoltaIcon from "components/VoltaIcon";

function SearchTextInput({ label, value, setValue, onSubmit }){
  function handleChange(e){
    if(setValue) setValue(e.target.value);
  }

  function handleKeyPress(e){
    if(e.key === "Enter" && onSubmit){
      onSubmit();
    }
  }

  return (
    <div className="Vlt-form__element">
      <label className="Vlt-label">
        {label}
      </label>
      <div className="Vlt-composite">
        <div className="Vlt-composite__prepend Vlt-composite__prepend--icon">
          <VoltaIcon icon="Vlt-icon-search" />
        </div>
        <div className="Vlt-input">
          <input 
            type="text" 
            value={value} 
            onChange={handleChange} 
            onKeyPress={handleKeyPress}
          />
        </div>
      </div>
    </div>
  )
}
export default SearchTextInput;