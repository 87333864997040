import { ErrorContext } from "./contexts/error";

import { useState, useEffect, useCallback } from "react";
import { useError } from "./hooks/error";

import SuccessMessage from "./models/success-message";
import Callouts from "./components/Callouts";

interface IErrorProvider {
  children: any;
}

function ErrorProvider({ children }: IErrorProvider){
  const [error, setError] = useState<Error | undefined>();
  const [success, setSuccess] = useState<SuccessMessage | undefined>();

  const throwError = useCallback(
    (err: Error) => {
      console.log(err.stack);
      setError(err);
    },
    []
  )

  function clearError(){
    setError(undefined);
  }

  const throwSuccess = useCallback(
    (msg: SuccessMessage) =>{
      setSuccess(msg);
    }
    ,
    []
  )

  useEffect(
    () => {
      if(error) (window as any).Volta.flash.show("critical", 5000);
    },
    [error]
  );

  useEffect(
    () => {
      if(success) (window as any).Volta.flash.show("success", 5000);
    },
    [success]
  )

  return (
    <ErrorContext.Provider
      value={{
        throwError,
        clearError,
        throwSuccess
      }}>
      <Callouts.Critical id="critical">
        {error? error.message: ""}
      </Callouts.Critical>
      <Callouts.Success id="success">
        {success? success.message: ""}
      </Callouts.Success>

      {children}
    </ErrorContext.Provider>
  )
}

export { SuccessMessage };
export { useError };
export default ErrorProvider;
