import { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";

import NumberService from "services/number";
import Dropdown from "components/Dropdown";
import PhoneNumber from "entities/number";

interface NumberDropdownProps {
  label?: string;
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
}

function NumberDropdown({ label, value, setValue, disabled }: NumberDropdownProps) {
  const [numbers, setNumbers] =  useState<PhoneNumber[]>([]);  

  useEffect(() => {
    async function fetchData(){
      const numbers = await NumberService.list();
      setNumbers(numbers);
    }
    fetchData();
  }, [])

  return (
    <Dropdown 
      label={label} 
      value={value} 
      setValue={setValue}
      disabled={disabled}
    >
      <option>--- Please Select ---</option>
      {numbers.map((number) => {
        return (
          <option key={number.id} value={number.id}>
            {number.number} | {number.geoCode}
          </option>
        )
      })}
    </Dropdown>
  )
}
export default NumberDropdown;