import { ChangeEvent, Dispatch, SetStateAction } from "react";

interface ITextInput {
  label?: string;
  hint?: string;
  value: string;
  setValue?: (Dispatch<SetStateAction<string>>) | ((value: string) => void);
  onClick?: () => void;
  disabled?: boolean;
}

function TextInput({ label, hint, value, setValue, onClick, disabled=false }: ITextInput) {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (setValue) setValue(e.target.value);
  }

  return (
    <div className="Vlt-form__element">
      {
        (!!label) && (
          <label className="Vlt-label">
            {label}
          </label>
        )
      }
      <div className="Vlt-input">
        <input 
          type="text" 
          value={value} 
          onChange={handleChange} 
          onClick={onClick}
          disabled={disabled} 
        />
      </div>

      {
        (!!hint) && (
          <small className="Vlt-form__element__hint">
            {hint}
          </small>
        )
      }
    </div>
  );
}
export default TextInput;