import React from "react";
import FetchHelper from "helper/fetch";
import useSDR from "../../hooks/sdr";

import Dropdown from "components/Dropdown";

function SDRDropdown({ label, value, setValue, disabled }){
  const mSDR = useSDR();

  React.useEffect(() => {
    FetchHelper.fetch(mSDR.fetch, () => {});
  }, [ mSDR.fetch ]);

  return (
    <Dropdown
      label={label}
      value={value}
      setValue={setValue}
      disabled={disabled}
    >
      <option>--- Please Select ---</option>
      {mSDR.sdrs?.map((sdr) => {
        return (
          <option key={sdr.id} value={sdr.id}>
            {sdr.name}
          </option>
        )
      })}
    </Dropdown>
  )
}
export default SDRDropdown;