import React from "react";

function SDRInformationCard(){
  return (
    <div className="Vlt-card--gradient-wrapper Vlt-gradient--blue-to-pink">
      <div className="Vlt-card Vlt-card--border">
        <div className="Vlt-card__content">
          <p>SDR</p>
          <h4>
            <b>Learn about SDRs definition here.</b>
          </h4>
          <p>An incoming call will be redirected to respective SDR. If SDR busy, the system will automatically go to next available SDR. However, when all SDR are busy, system will help to notify SDR.</p>
          <p>To understand SDR routes, you can go to <code>routes</code> menu. Configure SDR routes overthere.</p>
        </div>
      </div>
    </div>
  )
}
export default SDRInformationCard;
