import React from "react";
import SDR from "entities/sdr";
import FetchService from "services/fetch";
import api from "configs/api";

export const SDRContext = React.createContext({
  sdrs: [],
  create: () => Promise.resolve(),
  fetch: () => Promise.resolve(),
  destroy: () => Promise.resolve(),
  update: () => Promise.resolve(),
  assignSubordinates: () => Promise.resolve(),
  removeSubordinate: () => Promise.resolve()
});

export default function SDRProivder({ children }){
  const [ sdrs, setSDRs ] = React.useState([]);

  const fetch = React.useCallback(async () => {
    const url = `${api.url}/sdrs`;
    const responses = await FetchService.get(url);
    const sdrs = responses.map((response) => SDR.fromResponse(response));
    setSDRs(sdrs);
  }, [])

  async function assignSubordinates({ manager, subordinates }){
    const url = `${api.url}/sdrs/${manager.id}/subordinates`;
    const body = JSON.stringify({
      subordinates: subordinates.map((subordinate) => subordinate.id)
    });
    await FetchService.post(url, body);
  }

  async function removeSubordinate({ manager, subordinate }){
    const url = `${api.url}/sdrs/${manager.id}/subordinates/${subordinate.id}`;
    await FetchService.delete(url);
  }

  async function create({ sdr, manager }){
    const url = `${api.url}/sdrs`;
    const body = JSON.stringify(sdr.toRequest());
    const { id } = await FetchService.post(url, body);
    
    if(manager){
      const newSDR = new SDR(id);
      await assignSubordinates({ manager, subordinates: [newSDR] });
    }

    await fetch();
  }

  async function destroy({ sdr }){
    const url = `${api.url}/sdrs/${sdr.id}`;
    await FetchService.delete(url);
    await fetch();
  }

  async function update({ oldSDR, newSDR }){
    const url = `${api.url}/sdrs/${oldSDR.id}`;
    const body = JSON.stringify(newSDR.toRequest());
    await FetchService.put(url, body);
  }

  return (
    <SDRContext.Provider value={{
      sdrs,
      create,
      fetch,
      destroy,
      update,
      assignSubordinates,
      removeSubordinate
    }}>
      {children}
    </SDRContext.Provider>
  )
}