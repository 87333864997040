import PhoneNumber from "entities/number";
import { useHistory } from "react-router-dom";

import ButtonIcon from "components/ButtonIcon";

function DetailButton({ number }: { number: PhoneNumber }) {
  const { push } = useHistory();

  function handleClick(){
    push(`/numbers/${number.id}`);
  }
  return (
    <ButtonIcon icon="Vlt-icon-gear" onClick={handleClick} />
  )
}
export default DetailButton;
