import React from "react";
import clsx from "clsx";

function Badge({ id, label, onDismiss }){
  function handleDismiss(){
    if(onDismiss) onDismiss(id);
  }
  
  return (
    <div className={clsx(
      "Vlt-badge",
      "Vlt-badeg--transparent",
      "Vlt-badge--purple"
    )}>
      {label}
      <button className="Vlt-badge__dismiss" onClick={handleDismiss} />
    </div>
  )
}
export default Badge
