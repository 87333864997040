import PhoneNumber from "entities/number";

import Table from "components/Table";
import DetailButton from "../DetailButton";
import EditButton from "../EditButton";
import DeleteButton from "../DeleteButton";

interface IDetailColumn {
  number: PhoneNumber;
  onUpdated?: () => void
  onDeleted?: () => void;
}

function DetailColumn({ number, onUpdated, onDeleted }: IDetailColumn){
  return (
    <Table.Column className="Vlt-table__cell--nowrap">
      <DetailButton number={number} />
      <EditButton
        number={number}
        onUpdated={onUpdated}
      />
      <DeleteButton
        number={number}
        onDeleted={onDeleted}
      />
    </Table.Column>
  )
}
export default DetailColumn