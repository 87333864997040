import React from "react";
import clsx from "clsx";
import useStyles from "./styles";

import AddRouteModal from "../AddRouteModal";
import Button from "components/Button";

function Header(){
  const [ visible, setVisible ] = React.useState(false);
  const mStyles = useStyles();

  function handleOpenModal(){
    setVisible(true);
  }

  return (
    <React.Fragment>
      <div className="Vlt-grid">
        <div 
          className={clsx(
            "Vlt-col",
            mStyles.flexCenter
          )}
        >
          <h1 className={mStyles.title}>Routes</h1>
        </div>
        <div className="Vlt-col Vlt-right">
          <Button.Add onClick={handleOpenModal}>
            Add New Route
          </Button.Add>
        </div>
      </div>
      <AddRouteModal 
        visible={visible}
        setVisible={setVisible}
      />
    </React.Fragment>
  )
}
export default Header;