import clsx from "clsx";

function Spinner({ className, white=true }: { className?: string, white: boolean }) {
  return (
    <div 
      className={clsx(
      "Vlt-spinner",
      (white)? "Vlt-spinner--white": "",
      className
      )}
    />
  )
}

export default Spinner;
