import { useState } from "react";
import { v4 as uuid } from "uuid";

import NumberTable from "./components/NumberTable";
import Header from "./components/Header";
import NumberInformationCard from "components/NumberInformationCard";

function NumberPage(){
  const [refreshToken, setRefreshToken] = useState<string>(uuid());

  function handleNumberAdded() {
    setRefreshToken(uuid());
  }

  return (
    <>
      <Header onAdded={handleNumberAdded} />
      <hr />
      <div className="Vlt-grid">
        <div className="vlt-col Vlt-col--2of3">
          <NumberTable refreshToken={refreshToken} />
        </div>
        <div className="Vlt-col">
          <NumberInformationCard />
        </div>
      </div>
    </>
  )
}
export default NumberPage;