import React from "react";

import Table from "components/Table";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

function DetailColumn({ route }){
  return (
    <Table.Column className="Vlt-table__cell--nowrap">
      <EditButton route={route} />
      <DeleteButton route={route} />
    </Table.Column>
  )
}
export default DetailColumn