import clsx from "clsx";
import useStyles from "./styles";

import Head from "./components/Head";
import Header from "./components/Header";
import Body from "./components/Body";
import BodyRow from "./components/BodyRow";
import Column from "./components/Column";
import Row from "./components/Row";

interface ITable {
  children: any;
  classes?: {
    root: any,
    table: any
  }
}

function Table({ children, classes }: ITable){
  const mStyles = useStyles();
  
  return (
    <div 
      className={clsx(
        "Vlt-table", 
        "Vlt-table--nohighlight",
        classes?.root
      )}
    >
      <table 
        className={clsx(
          mStyles.table,
          classes?.table
        )}
      >
        {children}
      </table>
    </div>
  )
}

Table.Head = Head;
Table.Header = Header;
Table.Body = Body;
Table.BodyRow = BodyRow;
Table.Column = Column;
Table.Row = Row;
export default Table;
