import useStyles from "./styles";
import clsx from "clsx";

interface ISectionContainer {
  children: any;
  className?: any;
}

function SectionContainer({ children, className }: ISectionContainer){
  const mStyles = useStyles();

  return (
    <main className={clsx(mStyles.main, mStyles.mainLight, className)}>
      {children}
    </main>
  )
}
export default SectionContainer;