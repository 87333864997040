import React from "react";
import FetchHelper from "helper/fetch";

import useRoute from "../../hooks/route";
import { useError } from  "components/ErrorProvider";
import Num from "entities/number";

import ButtonIcon from "components/ButtonIcon";
import LoadingModal from "components/LoadingModal";

function DeleteButton({ route }){
  const [ isDeleting, setIsDeleting ] = React.useState(false);
  const mError = useError();
  const mRoute = useRoute();

  async function handleClick(){
    FetchHelper.fetch(mRoute.destroy, setIsDeleting, { 
      number: new Num({ id: route.number.id }),
      sdrs: route.sdrs
    }, {
      error: (err) => mError.throwError(err)
    })
  }

  return (
    <React.Fragment>
      <ButtonIcon 
        type="destructive"
        icon="Vlt-icon-bin"
        onClick={handleClick}
      />
      <LoadingModal visible={isDeleting} label="Deleting..." />
    </React.Fragment>
  )
}
export default DeleteButton;