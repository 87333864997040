import FetchHelper from "helper/fetch";

import { useState } from "react";
import { useError } from  "components/ErrorProvider";
import { useSession } from "components/SessionProvider";

import BigInput from "../BigInput";

function LoginForm() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoggingOn, setIsLoggingOn] = useState<boolean>(false);
  const { throwError } = useError();
  const { signin } = useSession();

  async function handleLoginClick() {
    FetchHelper.fetch(signin, setIsLoggingOn, { username, password }, {
      error: (err: Error) => {
        if (throwError) throwError(err)
      }
    })
  }

  return (
    <form className="Vlt-card Vlt-bg-white">
      <div className="Vlt-card__content">
        <h1>Login</h1>
        <BigInput 
          placeholder="Username" 
          label="Username" 
          value={username} 
          onChange={setUsername}
        />
        <BigInput
          type="password" 
          placeholder="Password" 
          label="Password"
          value={password} 
          onChange={setPassword}
        />
        <button 
          type="submit" 
          className="Vlt-btn Vlt-btn--secondary Vlt-btn--app" 
          onClick={handleLoginClick}
          disabled={isLoggingOn}
        >
          {
            isLoggingOn && (
              <span className="Vlt-spinner Vlt-spinner--smaller Vlt-spinner--white" />
            )
          }
          Login
        </button>
      </div>
    </form>
  );
}
export default LoginForm;