import ApiConfig from "configs/api";
import { SessionContext } from "./contexts/session";
import { SignInType } from "./types";

import { useState, useEffect } from "react";
import { useSession } from "./hooks/session";

import TokenService from "services/token";
import FetchService from "services/fetch";

interface ISessionProvider {
  children: any;
}

export function SessionProvider({ children }: ISessionProvider){
  const [loggedIn, setLoggedIn] = useState(false);

  async function signin({ username, password }: SignInType){
    const url = `${ApiConfig.url}/auth/signin`;
    const body = JSON.stringify({ username, password });
    const { access_token: accessToken } = await FetchService.post(url, body);
    TokenService.save("access_token", accessToken);
    setLoggedIn(true);
  }

  function signout() {
    TokenService.clear();
    setLoggedIn(false);
  }

  useEffect(
    () => {
      const accessToken = TokenService.retrieve("access_token");
      if(accessToken) setLoggedIn(true);
      else setLoggedIn(false);
    },
    []
  )

  return (
    <SessionContext.Provider
      value={{
        loggedIn,
        signin,
        signout
      }
    }>
      {children}
    </SessionContext.Provider>
  )
}

export { useSession };
export default SessionProvider;