export const initialState = {
  number: null,
  searchText: "",
  isLoading: false, 
  sdrs: []
}

export default function reducer(state, action){
  switch(action.type){
    case "CHANGE_NUMBER":
      return Object.assign({}, state, { number: action.value });
    case "CHANGE_SEARCH_TEXT":
      return Object.assign({}, state, { searchText: action.value });
    case "CLEAR_SEARCH_TEXT":
      return Object.assign({}, state, { searchText: "" });
    case "ADD_SDR":
      return Object.assign({}, state, { sdrs: [
        ...state.sdrs,
        action.value
      ]})
    case "REMOVE_SDR":
      return Object.assign({}, state, { sdrs: state.sdrs.filter((sdr) => {
        return sdr.id !== action.value.id
      })})
    case "START_LOADING":
      return Object.assign({}, state, { isLoading: true });
    case "STOP_LOADING":
      return Object.assign({}, state, { isLoading: false })
    case "CLEAR_INPUT":
      return initialState;
    default:
      throw new Error("Ops! something went wrong");
  }
}