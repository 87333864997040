import React from "react";
import FetchHelper from "helper/fetch";

import useNumber from "../../hooks/number";
import useHoliday from "../../hooks/holiday";

import CompactTable from "./CompactTable";

function HolidayTable({ compact=true }){
  const [ isFetching, setIsFetching ] = React.useState(true);
  const mNumber = useNumber();
  const mHoliday = useHoliday();

  React.useEffect(() => {
    FetchHelper.fetch(mHoliday.fetch, setIsFetching, {
      numberID: mNumber.number.id
    })
  }, [ mNumber.number.id, mHoliday.fetch]);

  if(compact) {
    return (
      <CompactTable 
        loading={isFetching} 
        holidays={mHoliday.holidays} 
        number={mNumber.number}
      />
    )
  }else return null
}
export default HolidayTable;
