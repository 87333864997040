import useStyles from "./styles";
import clsx from "clsx";

interface IPageContainer {
  children: any;
  className?: any;
}

function PageContainer({ children, className }: IPageContainer){
  const mStyles = useStyles();
  
  return (
    <main className={clsx(mStyles.container, className)}>
      {children}
    </main>
  )
}
export default PageContainer;