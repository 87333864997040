import React from "react";
import FetchHelper from "helper/fetch";

import useNumber from "../../hooks/number";
import { useParams } from "react-router-dom";

import HolidayTable from "../HolidayTable";
import PageHeader from "components/PageHeader";
import NumberInformationCard from "components/NumberInformationCard";
import FullPageSpinner from "components/FullPageSpinner";

function Main(){
  const [ isLoading, setIsLoading ] = React.useState(true);
  const mParams = useParams();
  const mNumber = useNumber();
  
  React.useEffect(() => {
    FetchHelper.fetch(mNumber.fetch, setIsLoading, {
      numberID: mParams.numberId
    })
  }, [ mParams.numberId, mNumber.fetch ])

  if(isLoading) return <FullPageSpinner />
  return (
    <>
      <PageHeader title="NUMBERS" name={mNumber.number?.geoName}/>
      <hr />
      <div className="Vlt-grid">
        <div className="Vlt-col Vlt-col--1of3">
          <HolidayTable compact />
        </div>
        <div className="Vlt-col Vlt-col--1of3"></div>
        <div className="Vlt-col Vlt-col--1of3">
          <NumberInformationCard />
        </div>
      </div>
    </>
  )
}
export default Main;