import React from "react";

import Button from "components/Button";
import Spinner from "components/Spinner";

function LoadingButton({ children, loading, onClick }){
  return (
    <Button 
      type="secondary"
      disabled={loading}
      onClick={onClick}
    >
      {loading?(
        <Spinner className="Vlt-spinner--smaller"/>
      ): null}
      {children}
    </Button>
  )
}
export default LoadingButton;