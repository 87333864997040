import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSession } from "components/SessionProvider";

import LoginForm from "./components/LoginForm";
import LoginContainer from "./components/LoginContainer";
import FormContainer from "./components/FormContainer";

function LoginPage(){
  const { loggedIn } = useSession();
  const { push } = useHistory();

  useEffect(
    () => {
      if(loggedIn) push("/numbers");
    },
    [loggedIn, push]
  );

  return(
    <LoginContainer>
      <FormContainer>
        <LoginForm />
      </FormContainer>
    </LoginContainer>
  )
}
export default LoginPage;