import clsx from "clsx";

import useStyles from "./styles";
import { useState } from "react";

import AddNumberModal from "../AddNumberModal";
import Button from "components/Button";

function Header({ onAdded }: { onAdded?: () => void }) {
  const [visible, setVisible] = useState(false);
  const mStyles = useStyles();

  function handleOpenModal(){
    setVisible(true);
  }

  return (
    <>
      <div className="Vlt-grid">
        <div 
          className={clsx(
            "Vlt-col",
            mStyles.flexCenter
          )}
        >
          <h1 className={mStyles.title}>Numbers</h1>
        </div>
        <div className="Vlt-col Vlt-right">
          <Button.Add onClick={handleOpenModal}>
            Add New Number
          </Button.Add>
        </div>
      </div>
      <AddNumberModal
        visible={visible}
        setVisible={setVisible}
        onAdded={onAdded}
      />
    </>
  )
}
export default Header;