import { DateTime } from "luxon";
import { Dispatch, SetStateAction } from "react";

import useNumber from "../../hooks/number";
import useHoliday from "../../hooks/holiday";
import { useState } from "react";
import { useError } from  "components/ErrorProvider";

import Holiday from "../../entities/holiday";
import SuccessMessage from "entities/success";

import TextInput from "components/TextInput";
import DatePicker from "components/DatePicker";
import Button from "components/Button";
import LoadingButton from "components/LoadingButton";

import Modal from "components/Modal";

interface AddHolidayModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

function AddHolidayModal({ visible, setVisible }: AddHolidayModalProps){
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<DateTime>(DateTime.local());
  const { number } = useNumber();
  const { throwSuccess, throwError } = useError();
  const { create } = useHoliday();

  function handleCancel(){
    setVisible(false);
  }

  async function handleAddNew(){
    if (!throwError) return;
    if (!throwSuccess) return;
    if (!number) return;
    if (!number.id) return;
    if (!create) return;

    try {
      setIsAdding(true);
        create({
          numberID: number.id,
          holiday: new Holiday({ name, date })
        })
        throwSuccess(new SuccessMessage("Holiday added"));
    } catch (err) {
      throwError(err);
    } finally {
      setIsAdding(false);
      setName("");
      setDate(DateTime.local());
      setVisible(false);
    }
  }

  return (
    <Modal visible={visible} size="small">
      <Modal.Header setVisible={setVisible}>
        <h4>Add New Holiday</h4>
      </Modal.Header>
      <Modal.Content>
        <TextInput 
          label="Name"
          value={name}
          setValue={setName}
        />
        <DatePicker 
          label="Date"
          value={date}
          setValue={setDate}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button type="tertiary" onClick={handleCancel} disabled={isAdding}>
          Cancel
        </Button>
        <LoadingButton loading={isAdding} onClick={handleAddNew}>
          Add New
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
export default AddHolidayModal;
