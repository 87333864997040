import { makeStyles } from "@material-ui/styles";
export default makeStyles(() => ({
  dateContainer: {
    borderLeft: "7px solid #b779ff",
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    marginBottom: 8
  },
  deleteContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}), { index: 1 })