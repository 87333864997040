import React from "react";

function Empty(){
  return (
    <div className="Vlt-empty">
      <div className="Vlt-empty__content">
        There's nothing in here
      </div>
    </div>
  )
}
export default Empty;