import { DateTime } from "luxon";
import { Dispatch, SetStateAction } from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import useStyles from "./styles";
import { useState } from "react";

import TextInput from "components/TextInput";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";

interface DatePickerProps {
  label?: string;
  hint?: string;
  value: DateTime;
  setValue: Dispatch<SetStateAction<DateTime>> | ((value: DateTime) => void);
}

function DatePicker({ label, hint, value, setValue }: DatePickerProps){
  const [open, setOpen] = useState(false);
  const mStyles = useStyles();

  function handleClick(){
    setOpen(true);
  }

  function handleChange(value: MaterialUiPickersDate){
    setOpen(false);
    if(value && setValue) setValue(value)
  }

  return (
    <>
      <MuiDatePicker
        className={mStyles.invisible}
        open={open}
        onChange={handleChange}
        value={value}
        autoOk
      />
      <TextInput 
        label={label}
        hint={hint}
        value={value.toLocaleString(DateTime.DATE_FULL)}
        onClick={handleClick}
      />
    </>
  );
}
export default DatePicker;