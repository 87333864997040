class SDR{
  // id: string
  // name: string
  // number: string
  // email: string
  // managers: Array<SDR>

  constructor(id, name, number, email){
    this.id = id;
    this.name = name;
    this.number = number;
    this.email = email;
    this.managers = [];
  }

  toRequest(){
    const jsonData = {
      id: this.id,
      name: this.name,
      number: this.number,
      email: this.email
    }
    return JSON.parse(JSON.stringify(jsonData));
  }

  toJSON(){ return this.toRequest(); }

  static fromResponse(value){
    const sdr = new SDR();
    sdr.id = value.id;
    sdr.name = value.name;
    sdr.number = value.number;
    sdr.email = value.email;

    if(value.managers){
      sdr.managers = value.managers.map((manager) => SDR.fromResponse(manager));
    }
    return sdr;
  }
}
export default SDR