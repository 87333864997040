import React from "react";
import countryLookup from "country-code-lookup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import useStyles from "./styles";
import useRoute from "../../hooks/route";

import NumberIndicator from "components/NumberIndicator";
import FlagIcon from "components/FlagIcon";
import Table from "components/Table";

import DetailColumn from "./DetailColumn";

function NormalTable({ limit=10 }){
  const [ combinedRoutes, setCombinedRoutes ] = React.useState([]);
  const mStyles = useStyles();
  const mRoute = useRoute();

  React.useEffect(() => {
    const combinedRoutes = [];
    for(const route of mRoute.routes){
      const existing = combinedRoutes.filter((r) => r.number.id === route.number.id).length > 0;

      if(!existing){
        const newRoute = route.toJSON();
        newRoute.sdrs = [ route.sdr ]
        combinedRoutes.push(newRoute);
      }else{
        const [ oldRoute ] = combinedRoutes.filter((r) => r.number.id === route.number.id);
        oldRoute.sdrs.push(route.sdr);
      }
    }
    setCombinedRoutes(combinedRoutes);
  }, [ mRoute.routes ])

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header />
          <Table.Header>NUMBER</Table.Header>
          <Table.Header>ASSIGNED SDR(s)</Table.Header>
          <Table.Header />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {combinedRoutes.map((route, index) => {
          const phoneNumber = parsePhoneNumberFromString(`+${route.number.number}`);
          const country = countryLookup.byIso(phoneNumber?.country ?? "AX");
          const countryName = country.country.toLowerCase().replace(/\s/, "-");
          return (
            <Table.BodyRow key={route.number.id}>
              <Table.Column>
                <NumberIndicator number={index + 1} />
              </Table.Column>
              <Table.Column>
                <p><b>{route.number.geoName} ({route.number.geoCode})</b></p>
                <p>
                  <FlagIcon icon={countryName} className={mStyles.flagIcon} />
                  {phoneNumber.formatInternational()}
                </p>
              </Table.Column>
              <Table.Column>
                {route.sdrs.map((sdr, index) => {
                  const phoneNumber = parsePhoneNumberFromString(`+${sdr.number}`);
                  const country = countryLookup.byIso(phoneNumber?.country ?? "AX");
                  const countryName = country.country.toLowerCase().replace(/\s/, "-");
                  return (
                    <React.Fragment key={sdr.id}>
                      <p><b>{sdr.name}</b></p>
                      <p className="Vlt-grey">
                        <FlagIcon icon={countryName} className={mStyles.flagIcon} />
                        {phoneNumber.formatInternational()}
                      </p>
                      <p className="Vlt-grey">{sdr.email}</p>
                      {(index + 1 < route.sdrs.length)? <hr />: null}
                    </React.Fragment>
                  )
                })}
              </Table.Column>
              <DetailColumn route={route} />
            </Table.BodyRow>
          )
        })}
      </Table.Body>
    </Table>
  )
}
export default NormalTable;