import clsx from "clsx";
import Holiday from "../../entities/holiday";
import { DateTime } from "luxon";

import useHoliday from "../../hooks/holiday";
import useNumber from "../../hooks/number";
import useStyles from "./styles";
import { useState } from "react";

import VoltaIcon from "components/VoltaIcon";
import FullPageSpinner from "components/FullPageSpinner";
import Empty from "components/Empty";
import LoadingModal from "components/LoadingModal";
import AddHolidayModal from "../AddHolidayModal";
import FetchHelper from "helper/fetch";

interface CompactTableProps {
  loading: boolean;
  holidays: Holiday[];
}

function CompactTable({ loading, holidays }: CompactTableProps) {
  const [visible, setVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { number } = useNumber();
  const { destroy } = useHoliday();
  const mStyles = useStyles();

  function handleAddClick(){
    setVisible(true);
  }

  async function handleDeleteHoliday(holiday: Holiday) {
    if (!number) return;
    if (!number.id) return;

    FetchHelper.fetch(destroy, setIsDeleting, {
      numberID: number.id,
      holidayID: holiday.id
    })
  }

  return (
    <>
      <div className="Vlt-card Vlt-card--border">
        <div className="Vlt-card__header">
          <h4>Holidays</h4>
        </div>
        <div className="Vlt-card__corner">
          <VoltaIcon
            icon="Vlt-icon-calendar-add"
            onClick={handleAddClick}
          />
        </div>
        <div className="Vlt-card__content">
          {
            loading? <FullPageSpinner />: 
            holidays.length === 0? <Empty />: 
            holidays.map((holiday) => {
              return (
                <div key={holiday.id} className={clsx("Vlt-grid", mStyles.dateContainer)}>
                  <div className="Vlt-col">
                    <p>
                      <b>{holiday.name}</b>
                    </p>
                    <p className="Vlt-grey-darker">{holiday.date.toLocaleString(DateTime.DATE_FULL)}</p>
                  </div>
                  <div className={clsx("Vlt-col", mStyles.deleteContainer)}>
                    <VoltaIcon 
                      icon="Vlt-icon-bin" 
                      className="Vlt-red Vlt-icon--small" 
                      onClick={() => handleDeleteHoliday(holiday)} 
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <AddHolidayModal 
        visible={visible}
        setVisible={setVisible}        
      />
      <LoadingModal visible={isDeleting} label="Deleting Holiday..."/>
    </>
  );
}
export default CompactTable;
