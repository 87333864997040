import { DateTime } from "luxon";

export const initialState = {
  number: "",
  geoCode: "",
  geoName: "",
  timezone: DateTime.local().zoneName,
  startBusinessHour: DateTime.fromObject({ hour: 9 }),
  endBusinessHour: DateTime.fromObject({ hour: 17 })
}

export default function reducer(state: any, action: any){
  switch(action.type){
    case "CHANGE_NUMBER":
      return Object.assign({}, state, { number: action.value.replace(/\s+/g, '').split("+").join("") });
    case "CHANGE_GEO_CODE":
      return Object.assign({}, state, { geoCode: action.value });
    case "CHANGE_GEO_NAME":
      return Object.assign({}, state, { geoName: action.value });
    case "CHANGE_TIMEZONE":
      return Object.assign({}, state, { timezone: action.value });
    case "CHANGE_START_BUSINESS_HOUR":
      return Object.assign({}, state, { startBusinessHour: action.value });
    case "CHANGE_END_BUSINESS_HOUR":
      return Object.assign({}, state, { endBusinessHour: action.value });
    case "INIT_VALUE":
      return Object.assign({}, state, {
        number: action.value.number,
        geoCode: action.value.geoCode,
        geoName: action.value.geoName,
        timezone: action.value.timezone,
        startBusinessHour: action.value.startBusinessHour,
        endBusinessHour: action.value.endBusinessHour
      });
    case "CLEAR_INPUT":
      return initialState;
    default:
      throw new Error("Ops! something went wrong");
  }
}