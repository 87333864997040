import clsx from "clsx";

interface IModalContent {
  children: any;
  className?: string[]
}

function ModalContent({ children, className=[] }: IModalContent) {
  return (
    <div className={clsx("Vlt-modal__content", ...className)}>
      {children}
    </div>
  )
}
export default ModalContent;