import { createContext } from "react";
import { useCallback, useState } from "react";

import { FetchOptions, NumberContextValues, NumberProviderProps } from "../types";

import api from "configs/api";
import FetchService from "services/fetch";
import PhoneNumber from "entities/number";

export const NumberContext = createContext<Partial<NumberContextValues>>({})

export default function NumberProvider({ children }: NumberProviderProps){
  const [number, setNumber] = useState<PhoneNumber | undefined>();

  const fetch = useCallback(
    async ({ numberID }: FetchOptions) => {
      const url = `${api.url}/numbers/${numberID}`;
      const response = await FetchService.get(url);
      const number = PhoneNumber.fromResponse(response);
      setNumber(number);
    },
    []
  )

  return (
    <NumberContext.Provider value={{
      fetch,
      number
    }}>
      {children}
    </NumberContext.Provider>
  )
}