import React from "react";
import FetchHelper from "helper/fetch";
import useSDR from "../../hooks/sdr";

import ButtonIcon from "components/ButtonIcon";
import LoadingModal from "components/LoadingModal";

function DeleteButton({ sdr }){
  const [ isDeleting, setIsDeleting ] = React.useState(false);
  const mSDR = useSDR();

  function handleClick(){
    FetchHelper.fetch(mSDR.destroy, setIsDeleting, { sdr }) 
  }

  return (
    <React.Fragment>
      <ButtonIcon 
        type="destructive"
        icon="Vlt-icon-bin"
        onClick={handleClick}
      />
      <LoadingModal visible={isDeleting} label="Deleting..." />
    </React.Fragment>
  )
}
export default DeleteButton;