import TokenService from "./token";

class FetchService{
  static isJSONResponse(response: Response) {
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.indexOf("application/json") >= 0) return true;
    else return false;
  }

  static async fetch(url: string, options?: RequestInit) {
    const response = await fetch(url, options);

    if (response.ok) {
      if (FetchService.isJSONResponse(response)) {
        const jsonResponse = await response.json();
        return jsonResponse;
      } else return undefined;
    } else {
      if (FetchService.isJSONResponse(response)) {
        const jsonResponse = await response.json();
        throw new Error(jsonResponse.message);
      } else {
        const bodyResponse = await response.text();
        console.log(bodyResponse);
        throw new Error(response.statusText);
      }
    }
  }

  static async get(url: string, accessToken = TokenService.retrieve("access_token")) {
    const headers = JSON.parse(JSON.stringify({
      Accept: "application/json",
      Authorization: accessToken? `Bearer ${accessToken}`: undefined
    }));

    const options = { method: "GET", headers };
    return FetchService.fetch(url, options);
  }

  static async post(url: string, body: any, accessToken = TokenService.retrieve("access_token")) {
    const headers =  JSON.parse(JSON.stringify({ 
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: accessToken? `Bearer ${accessToken}`: undefined
    }));

    const options = { method: "POST", headers, body }
    return FetchService.fetch(url, options);
  }

  static async delete(url: string, body?: any, accessToken = TokenService.retrieve("access_token")) {
    const headers = JSON.parse(JSON.stringify({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: accessToken? `Bearer ${accessToken}`: undefined
    }));

    const options = JSON.parse(JSON.stringify({ method: "DELETE", body, headers }));
    return FetchService.fetch(url, options);
  }

  static async put(url: string, body: any, accessToken = TokenService.retrieve("access_token")) {
    const headers =  JSON.parse(JSON.stringify({ 
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: accessToken? `Bearer ${accessToken}`: undefined
    }));

    const options = { method: "PUT", headers, body }
    return FetchService.fetch(url, options);
  }
}
export default FetchService;