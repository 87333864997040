import clsx from "clsx";

function TableRow({ className, children }: { className?: any, children: any }) {
  return (
    <tr className={clsx(className)}>
      {children}
    </tr>
  )
}
  
export default TableRow;