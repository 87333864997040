import React from "react";

function RouteInformationCard(){
  return (
    <div className="Vlt-card--gradient-wrapper Vlt-gradient--blue-to-pink">
      <div className="Vlt-card Vlt-card--border">
        <div className="Vlt-card__content">
          <p>ROUTES</p>
          <h4>
            <b>Learn about routes definition here.</b>
          </h4>
          <p>One number can have multiple SDRs to be assigned. A specific mechnism is implemented to select specific SDR when an incoming call is coming.</p>
        </div>
      </div>
    </div>
  )
}
export default RouteInformationCard;
