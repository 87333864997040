import voltaIcons from "@vonagevolta/volta2/dist/symbol/volta-icons.svg";
import clsx from "clsx";

interface IButtonIcon {
  onClick?: () => void;
  icon?: String;
  className?: String,
  type?: "tertiary" | "primary" | "secondary" | "destructive";
}

function ButtonIcon({ onClick, icon, className, type="tertiary" }: IButtonIcon){
  return (
    <button 
      className={clsx(
        "Vlt-btn",
        `Vlt-btn--${type}`,
        "Vlt-btn--icon",
        className
      )}
      onClick={onClick}
    >
      <svg>
        <use xlinkHref={`${voltaIcons}#${icon}`} />
      </svg>
    </button>
  )
}

export default ButtonIcon;
