import SDR from "entities/sdr";

export const initialState = {
  name: "",
  number: "",
  email: "",
  manager: ""
}

export default function reducer(state, action){
  switch(action.type){
    case "CHANGE_NAME":
      return Object.assign({}, state, { name: action.value });
    case "CHANGE_NUMBER":
      return Object.assign({}, state, { number: action.value });
    case "CHANGE_EMAIL":
      return Object.assign({}, state, { email: action.value });
    case "CHANGE_MANAGER":
      return Object.assign({}, state, { manager: new SDR(action.value) });
    case "INIT_VALUE":
      const [ manager ] = action.value.managers;
      return Object.assign({}, state, {
        name: action.value.name,
        number: action.value.number,
        email: action.value.email,
        manager: new SDR(manager?.id)
      })
    case "CLEAR_INPUT":
      return initialState;
    default:
      throw new Error("Ops! something went wrong");
  }
}