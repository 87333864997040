import { DateTime } from "luxon";

interface IConstructor {
  id?: string;
  number: string;
  geoCode: string;
  geoName: string;
  timezone: string;
  startBusinessHour: DateTime;
  endBusinessHour: DateTime;
}

class PhoneNumber {
  id?: string;
  number: string;
  geoCode: string;
  geoName: string;
  timezone: string;
  startBusinessHour: DateTime;
  endBusinessHour: DateTime;

  constructor(args: IConstructor) {
    this.id = args.id;
    this.number = args.number;
    this.geoCode = args.geoCode;
    this.geoName = args.geoName;
    this.timezone = args.timezone;
    this.startBusinessHour = args.startBusinessHour;
    this.endBusinessHour = args.endBusinessHour;
  }

  toRequest(){
    const jsonData = {
      id: this.id,
      number: this.number,
      geoCode: this.geoCode,
      geoName: this.geoName,
      timezone: this.timezone,
      startBusinessHour: parseInt(this.startBusinessHour.toFormat("HH")),
      endBusinessHour: parseInt(this.endBusinessHour.toFormat("HH"))
    }
    return JSON.parse(JSON.stringify(jsonData));
  }

  toJSON() {
    return this.toRequest()
  }

  static fromResponse(value: any): PhoneNumber {
    return new PhoneNumber({
      id: value.id,
      number: value.number,
      geoCode: value.geoCode,
      geoName: value.geoName,
      timezone: value.timezone,
      startBusinessHour: DateTime.fromObject({ hour: value.startBusinessHour, zone: value.timezone }),
      endBusinessHour: DateTime.fromObject({ hour: value.endBusinessHour, zone: value.timezone })
    });
  }
}

export default PhoneNumber;
