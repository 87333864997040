import React from "react";
import FetchHelper from "helper/fetch";
import useSDR from "../../hooks/sdr";

import SDR from "entities/sdr";
import reducer, { initialState } from "./reducer";

import SDRDropdown from "../SDRDropdown";
import Button from "components/Button";
import TextInput from "components/TextInput";
import LoadingButton from "components/LoadingButton";

import Modal from "components/Modal";

function EditSDRModal({ sdr:selectedSDR, visible, setVisible, onEdited }){
  const [ state, dispatch ] = React.useReducer(reducer, initialState);
  const [ isEditing, setIsEditing ] = React.useState(false);
  const mSDR = useSDR();
  const {
    name,
    number,
    email, 
    manager
  } = state;

  function handleCancel(){
    setVisible(false);
  }

  function handleNumberChange(value){
    dispatch({ type: "CHANGE_NUMBER", value });
  }

  function handleNameChange(value){
    dispatch({ type: "CHANGE_NAME", value });
  }

  function handleEmailChange(value){
    dispatch({ type: "CHANGE_EMAIL", value });
  }

  function handleManagerChange(value){
    dispatch({ type: "CHANGE_MANAGER", value });
  }

  async function handleEdit(){
    FetchHelper.fetch(async ({ oldSDR, newSDR, newManager }) => {
      await mSDR.update({ oldSDR, newSDR });

      const [ oldManager ] = oldSDR.managers;
      if(oldManager) await mSDR.removeSubordinate({ manager: oldManager, subordinate: oldSDR });
      if(newManager) await mSDR.assignSubordinates({ manager: newManager, subordinates: [newSDR] });
      await mSDR.fetch();
    }, setIsEditing, { 
      oldSDR: selectedSDR, 
      newSDR: new SDR(selectedSDR.id, name, number, email),
      newManager: manager.id? new SDR(manager.id): undefined
    }, {
      finish: () => dispatch({ type: "CLEAR_INPUT" }),
      finally: () => {
        setIsEditing(false);
        setVisible(false);
      }
    })
  }

  React.useEffect(() => {
    if(visible) dispatch({ type: "INIT_VALUE", value: selectedSDR })
  }, [ visible, selectedSDR ])

  return (
    <Modal visible={visible} size="small">
      <Modal.Header setVisible={setVisible}>
        <h4>Edit SDR</h4>
      </Modal.Header>
      <Modal.Content>
        <TextInput 
          label="Name" 
          value={name}
          setValue={handleNameChange}
        />
        <TextInput 
          label="Number" 
          value={number}
          setValue={handleNumberChange}
          hint="Without + and space. Only number with country code."
        />
        <TextInput 
          label="Email" 
          value={email}
          setValue={handleEmailChange}
        />
        <SDRDropdown 
          label="Manager"
          value={manager.id}
          setValue={handleManagerChange}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button 
          type="tertiary" 
          onClick={handleCancel}
          disabled={isEditing}
        >
          Cancel
        </Button>
        <LoadingButton loading={isEditing} onClick={handleEdit}>
          Apply
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
export default EditSDRModal