import { useSession } from "components/SessionProvider";
import { Route, Redirect } from "react-router-dom";

// @ts-ignore
function PrivateRoute({ children, ...rest }) {
  const mSession = useSession();

  return (
    <Route
      {...rest}
      render={() =>
        mSession.loggedIn? (
          children
        ) : <Redirect to="/login" />
      }
    />
  );
}
export default PrivateRoute;