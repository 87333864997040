import clsx from "clsx";
import useStyles from "./styles";

function TableHeader({ className, children }: { className?: any, children?: any }) {
  const mStyles = useStyles();

  return (
    <th 
      className={clsx(
        mStyles.root, 
        "Vlt-grey",
        className
      )}
    >
      {children}
    </th>
  )
}

export default TableHeader;