import React from "react";

function NumberInformationCard(){
  return (
    <div className="Vlt-card--gradient-wrapper Vlt-gradient--blue-to-pink">
      <div className="Vlt-card Vlt-card--border">
        <div className="Vlt-card__content">
          <p>NUMBERS</p>
          <h4>
            <b>Learn about numbers definition here.</b>
          </h4>
          <p>Numbers are our contact center number. You should put a real number here. Furthermore, you need to link a number to application from Vonage API dashboard.</p>
          <p>Rule of thumb for country code is you need to use 3 characters ISO country code. For continent, you can use anything as long as it is 3 characters </p>
        </div>
      </div>
    </div>
  )
}
export default NumberInformationCard;
