import PhoneNumber from "entities/number";

import useStyles from "./styles";
import { useState } from "react";

import ButtonIcon from "components/ButtonIcon";
import EditNumberModal from "../EditNumberModal";

interface IEditButton {
  number: PhoneNumber;
  onUpdated?: () => void;
}

function EditButton({ number, onUpdated }: IEditButton) {
  const [visible, setVisible] = useState(false);
  const mStyles = useStyles();

  function handleClick(){
    setVisible(true);
  }

  return (
    <>
      <ButtonIcon 
        icon="Vlt-icon-edit"
        onClick={handleClick}
        className={mStyles.marginRight}
      />
      <EditNumberModal 
        number={number}
        visible={visible}
        setVisible={setVisible}
        onUpdated={onUpdated}
      />
    </>
  )
}
export default EditButton;