import { Dispatch, SetStateAction } from "react";
import { DateTime } from "luxon";

import { useState, useReducer } from "react";
import { useError } from  "components/ErrorProvider";

import PhoneNumber from "entities/number";
import reducer, { initialState } from "./reducer";

import Button from "components/Button";
import TextInput from "components/TextInput";
import TimePicker from "components/TimePicker";
import TimezoneDropdown from "components/TimezoneDropdown";
import LoadingButton from "components/LoadingButton";

import Modal from "components/Modal";
import NumberService from "services/number";

interface IAddNumberModal {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onAdded?: () => void;
}

function AddNumberModal({ visible, setVisible, onAdded }: IAddNumberModal) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [ isAdding, setIsAdding ] = useState(false);
  const { throwError } = useError();
  const {
    number,
    geoCode,
    geoName,
    timezone,
    startBusinessHour,
    endBusinessHour
  } = state;

  function handleCancel() {
    setVisible(false);
  }

  function handleNumberChange(value: string) {
    dispatch({ type: "CHANGE_NUMBER", value });
  }

  function handleGeoCodeChange(value: string) {
    dispatch({ type: "CHANGE_GEO_CODE", value });
  }

  function handleGeoNameChange(value: string) {
    dispatch({ type: "CHANGE_GEO_NAME", value });
  }

  function handleTimezoneChange(value: string) {
    dispatch({ type: "CHANGE_TIMEZONE", value });
  }
  
  function handleStartBusinessHourChange(value: DateTime) {
    dispatch({ type: "CHANGE_START_BUSINESS_HOUR", value });
  }

  function handleEndBusinessHourChange(value: DateTime) {
    dispatch({ type: "CHANGE_END_BUSINESS_HOUR", value });
  }

  async function handleAddNew(){
    try {
      setIsAdding(true);
      const num = new PhoneNumber({
        number,
        geoCode,
        geoName,
        timezone,
        startBusinessHour,
        endBusinessHour
      });

      await NumberService.create(num);
      dispatch({ type: "CLEAR_INPUT" })
      if (onAdded) onAdded();
    } catch (err) {
      if (throwError) throwError(err);
    } finally {
      setIsAdding(false);
      setVisible(false);
    }
  }

  return (
    <Modal visible={visible} size="small">
      <Modal.Header setVisible={setVisible}>
        <h4>Add New Number</h4>
      </Modal.Header>
      <Modal.Content>
        <div className="Vlt-grid">
          <div className="Vlt-col Vlt-grid__separator">
            <TextInput 
              label="Number" 
              value={number}
              setValue={handleNumberChange}
              hint="Without + and space. Only number with country code."
            />
          </div>
          <div className="Vlt-col Vlt-grid__separator">
            <TextInput 
              label="Geo Code" 
              value={geoCode}
              setValue={handleGeoCodeChange}
              hint="SEA for South East Asia, or CHN for China. ISO 3 characters."
            />
          </div>
          <div className="Vlt-col Vlt-grid__separator">
            <TextInput 
              label="Geo Name" 
              value={geoName}
              setValue={handleGeoNameChange}
              hint="China, South East Asia or anything related to Geography location name."
            />
          </div>
          <div className="Vlt-col Vlt-grid__separator">
            <TimezoneDropdown 
              label="Timezone" 
              value={timezone} 
              setValue={handleTimezoneChange} 
            />
          </div>
          <div className="Vlt-col Vlt-col--A">
            <TimePicker 
              label="Start Business Hour" 
              value={startBusinessHour} 
              setValue={handleStartBusinessHourChange }
            />
          </div>
          <div className="Vlt-col Vlt-col--A">
            <TimePicker 
              label="End Business Hour"
              value={endBusinessHour}
              setValue={handleEndBusinessHourChange}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          type="tertiary" 
          onClick={handleCancel}
          disabled={isAdding}
        >
          Cancel
        </Button>
        <LoadingButton loading={isAdding} onClick={handleAddNew}>
          Add New
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default AddNumberModal;
