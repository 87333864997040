interface ICallouts { 
  id: string;
  children: any;
}

function SuccessCallouts({ id, children }: ICallouts){
  return (
    <div className="Vlt-flash Vlt-callout Vlt-callout--good" id={id}>
      <i/>
      <div className="Vlt-callout__content">
        <p>{children}</p>
      </div>
    </div> 
  )
}

function CriticalCallouts({ id, children }: ICallouts){
  return (
    <div className="Vlt-flash Vlt-callout Vlt-callout--critical" id={id}>
      <i/>
      <div className="Vlt-callout__content">
        <p>{children}</p>
      </div>
    </div> 
  )
}

function Callouts() {}
Callouts.Success = SuccessCallouts;
Callouts.Critical = CriticalCallouts;

export default Callouts;