import { createContext } from "react";
import { SignInType } from "../types";

type SessionContextType = {
  loggedIn: boolean;
  signin: (args: SignInType) => Promise<void>;
  signout: () => void;
}

export const SessionContext = createContext<Partial<SessionContextType>>({});
