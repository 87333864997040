import NumberService from "services/number";
import PhoneNumber from "entities/number";

import { useState, useEffect, useCallback } from "react";
import { useError } from "components/ErrorProvider";

import Empty from "components/Empty";
import FullPageSpinner from "components/FullPageSpinner";
import NormalTable from "./components/NormalTable";

function NumberTable({ refreshToken }: { refreshToken: string }){
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [numbers, setNumbers] = useState<PhoneNumber[]>([]);
  const { throwError } = useError();

  const fetchNumbers = useCallback(
    async () => {
      try {
        setIsFetching(true);
        const numbers = await NumberService.list();
        setNumbers(numbers);

      } catch (err) {
        if (throwError) throwError(err);
      } finally {
        setIsFetching(false);
      }
    },
    [throwError]
  )

  useEffect(() => {
    fetchNumbers();
  }, [fetchNumbers, refreshToken]);

  if (isFetching) return <FullPageSpinner />
  else if (numbers.length <= 0) return <Empty />;
  else {
    return (
      <NormalTable
        numbers={numbers}
        onUpdated={fetchNumbers}
        onDeleted={fetchNumbers}
      />
    )
  }
}
export default NumberTable;