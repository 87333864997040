import React from "react";
import useSDR from "../../hooks/sdr";
import FetchHelper from "helper/fetch";

import SDR from "entities/sdr";
import reducer, { initialState } from "./reducer";

import SDRDropdown from "..//SDRDropdown";
import Button from "components/Button";
import TextInput from "components/TextInput";
import LoadingButton from "components/LoadingButton";

import Modal from "components/Modal";

function AddSDRModal({ visible, setVisible }){
  const [ state, dispatch ] = React.useReducer(reducer, initialState);
  const [ isAdding, setIsAdding ] = React.useState(false);
  const mSDR = useSDR();
  const {
    name,
    number,
    email, 
    manager
  } = state;

  function handleCancel(){
    setVisible(false);
  }

  function handleNumberChange(value){
    dispatch({ type: "CHANGE_NUMBER", value });
  }

  function handleNameChange(value){
    dispatch({ type: "CHANGE_NAME", value });
  }

  function handleEmailChange(value){
    dispatch({ type: "CHANGE_EMAIL", value });
  }

  function handleManagerChange(value){
    dispatch({ type: "CHANGE_MANAGER", value });
  }

  async function handleAddNew(){
    const sdr = new SDR(undefined, name, number, email);
    const sdrManager = manager.id? new SDR(manager.id): undefined;
    FetchHelper.fetch(mSDR.create, setIsAdding, { sdr, manager: sdrManager }, {
      finish: () => dispatch({ type: "CLEAR_INPUT" }),
      finally: () => {
        setIsAdding(false);
        setVisible(false);
      }
    })
  }

  return (
    <Modal visible={visible} size="small">
      <Modal.Header setVisible={setVisible}>
        <h4>Add New SDR</h4>
      </Modal.Header>
      <Modal.Content>
        <TextInput 
          label="Name" 
          value={name}
          setValue={handleNameChange}
        />
        <TextInput 
          label="Number" 
          value={number}
          setValue={handleNumberChange}
          hint="Without + and space. Only number with country code."
        />
        <TextInput 
          label="Email" 
          value={email}
          setValue={handleEmailChange}
        />
        <SDRDropdown 
          label="Manager"
          value={manager.id}
          setValue={handleManagerChange}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button 
          type="tertiary" 
          onClick={handleCancel}
          disabled={isAdding}
        >
          Cancel
        </Button>
        <LoadingButton loading={isAdding} onClick={handleAddNew}>
          Add New
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
export default AddSDRModal