import React from "react";
import FetchHelper from "helper/fetch";
import SDRService from "services/sdr";

import useStyles from "./styles";
import useRoute from "../../hooks/route";
import { useError } from  "components/ErrorProvider";

import reducer, { initialState } from "./reducer";
import CustomError from "entities/error";
import SDR from "entities/sdr";
import { default as Num } from "entities/number";


import NumberDropdown from "..//NumberDropdown";
import LoadingButton from "components/LoadingButton";
import Button from "components/Button";
import SearchTextInput from "components/SearchTextInput";
import Badge from "components/Badge";

import Modal from "components/Modal";

function AddSDRModal({ visible, setVisible, onAdded }){
  const [ state, dispatch ] = React.useReducer(reducer, initialState);
  const mStyles = useStyles();
  const mError = useError();
  const mRoute = useRoute();

  function handleDismiss(id){
    const sdr = new SDR(id);
    dispatch({ type: "REMOVE_SDR", value: sdr });
  }

  function handleSearchChange(value){
    dispatch({ type: "CHANGE_SEARCH_TEXT", value });
  }

  function handleNumberChange(value){
    dispatch({ type: "CHANGE_NUMBER", value });
  }

  async function handleSearchSubmit(){
    const sdr = new SDR(undefined, state.searchText);
    FetchHelper.fetch(async ({ sdr }) => {
      const sdrs = await SDRService.search(sdr);
      if(sdrs.length > 1) throw new CustomError("AddRouteModal/handleSearchSubmit", "Please be more specific for SDR name");
      else if (sdrs.length === 0) throw new CustomError("AddRouteModal/handleSearchSubmit", "Could not find the SDR with given name. Please be more specific") 
      else {
        const [ sdr ] = sdrs;
        dispatch({ type: "ADD_SDR", value: sdr })
      }
    }, () => {}, { sdr }, {
      error: (err) => mError.throwError(err),
      finally: () => dispatch({ type: "CLEAR_SEARCH_TEXT" })
    })
  }

  function handleCancelClick(){
    setVisible(false);
  }

  async function handleAddNew(){
    const number = new Num({ id: state.number });
    FetchHelper.fetch(mRoute.create, (loading) => {
      if(loading) dispatch({ type: "START_LOADING" })
      else dispatch({ type: "STOP_LOADING" })
    }, { number, sdrs: state.sdrs }, {
      error: (err) => mError.throwError(err),
      finally: () => {
        dispatch({ type: "CLEAR_INPUT" });
        setVisible(false);
      }
    })
  }
  
  return (
    <Modal visible={visible} size="small">
      <Modal.Header setVisible={setVisible}>
        <h4>Add New Route</h4>
      </Modal.Header>
      <Modal.Content>
        <NumberDropdown 
          label="Number" 
          value={state.number}
          setValue={handleNumberChange}
        />
        <SearchTextInput 
          label="Search SDR" 
          value={state.searchText} 
          setValue={handleSearchChange} 
          onSubmit={handleSearchSubmit}
        />
        <div className={mStyles.badgeContainer}>
          {state.sdrs.map((sdr) => {
            return (
              <Badge 
                id={sdr.id}
                key={sdr.id}
                label={sdr.name} 
                onDismiss={handleDismiss} 
              />
            )
          })}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          type="tertiary" 
          disabled={state.isLoading}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <LoadingButton loading={state.isLoading} onClick={handleAddNew}>
          Add new
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
export default AddSDRModal