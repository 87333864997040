import FetchHelper from "helper/fetch";
import { Dispatch, SetStateAction } from "react";

import SDRService from "services/sdr";
import NumberService from "services/number";

import useStyles from "./styles";
import useRoute from "../../hooks/route";
import { useState, useReducer, useEffect } from "react";
import { useError } from  "components/ErrorProvider";

import reducer, { initialState } from "./reducer";
import Route from "../../entities/route";
import CustomError from "entities/error";
import SDR from "entities/sdr";

import NumberDropdown from "..//NumberDropdown";
import LoadingButton from "components/LoadingButton";
import Button from "components/Button";
import SearchTextInput from "components/SearchTextInput";
import Badge from "components/Badge";
import Modal from "components/Modal";

interface EditModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  route: Route;
}

function EditRouteModal({ visible, setVisible, route }: EditModalProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [, setSearching] = useState<boolean>(false);
  const { throwError } = useError();
  const { create, destroy, fetch } = useRoute();
  const mStyles = useStyles();

  function handleDismiss(id: string){
    const sdr = new SDR(id);
    dispatch({ type: "REMOVE_SDR", value: sdr });
  }

  function handleSearchChange(value: string){
    dispatch({ type: "CHANGE_SEARCH_TEXT", value });
  }

  function handleCancelClick(){
    setVisible(false);
  }

  async function handleSearchSubmit(){
    const sdr = new SDR(undefined, state.searchText);

    FetchHelper.fetch(
      async ({ sdr }: { sdr: SDR }) => {
        const sdrs = await SDRService.search(sdr);
        if(sdrs.length > 1) throw new CustomError("EditRouteModal/handleSearchSubmit", "Please be more specific for SDR name");
        else if (sdrs.length === 0) throw new CustomError("EditRouteModal/handleSearchSubmit", "Could not find the SDR with given name. Please be more specific") 
        else {
          const [ sdr ] = sdrs;
          dispatch({ type: "ADD_SDR", value: sdr })
        }
      },
      setSearching,
      { sdr },
      {
        error: (err: Error) => throwError(err),
        finally: () => dispatch({ type: "CLEAR_SEARCH_TEXT" })
      }
    )
  }

  async function handleEdit(){
    try {
      dispatch({ type: "START_LOADING" });
      if (!state.number) throw new CustomError("EditRouteModal/handleEdit", "Could not fetch number. Please try again");
      if (!route.sdrs) throw new CustomError("EditRouteModal/handleEdit", "System issue. Please contact developer");
      
      const addedSDRs = state.sdrs.filter(
        (sdr) => {
          if (!route.sdrs) throw new CustomError("EditRouteModal/handleEdit", "System issue. Please contact developer");
          return !route.sdrs.includes(sdr)
        }
      );

      const removedSDRs = route.sdrs.filter((sdr) => !state.sdrs.includes(sdr));
      const number = await NumberService.retrieve(state.number);

      console.log(addedSDRs, removedSDRs);

      await Promise.all([
        create({ number, sdrs: addedSDRs }),
        destroy({ number, sdrs: removedSDRs })
      ]);
      fetch();

    } catch (err) {
      throwError(err);
    } finally {
      dispatch({ type: "STOP_LOADING"});
      setVisible(false);
    }
  }

  useEffect(() => {
    if (route && route.number?.id && route.sdrs !== undefined) {
      dispatch({ type: "INIT", value: {
        number: route.number.id,
        sdrs: route.sdrs
      }})
    }
  }, [ route ])
  
  return (
    <Modal visible={visible} size="small">
      <Modal.Header setVisible={setVisible}>
        <h4>Edit Route</h4>
      </Modal.Header>
      <Modal.Content>
        <NumberDropdown 
          label="Number" 
          value={state.number ?? ""}
          disabled
        />
        <SearchTextInput 
          label="Search SDR" 
          value={state.searchText} 
          setValue={handleSearchChange} 
          onSubmit={handleSearchSubmit}
        />
        <div className={mStyles.badgeContainer}>
          {state.sdrs?.map((sdr) => {
            return (
              <Badge 
                id={sdr.id}
                key={sdr.id}
                label={sdr.name} 
                onDismiss={handleDismiss} 
              />
            )
          })}
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button
          type="tertiary"
          onClick={handleCancelClick}
          disabled={state.isLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={state.isLoading}
          onClick={handleEdit}
        >
          Apply
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}
export default EditRouteModal