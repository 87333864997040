import api from "configs/api";
import FetchService from "services/fetch";

import SDR from "entities/sdr";
import PhoneNumber from "entities/number";

class NumberService {
  static async retrieve(id: string): Promise<PhoneNumber> {
    const url = `${api.url}/numbers/${id}`;
    const response = await FetchService.get(url);
    return PhoneNumber.fromResponse(response);
  }

  static async list(): Promise<PhoneNumber[]>{
    const url = `${api.url}/numbers`;
    const responses = await FetchService.get(url);
    const numbers = responses.map(
      (response: Record<string, any>) => {
        return PhoneNumber.fromResponse(response);
      }
    );
    return numbers;
  }

  static async create(number: PhoneNumber) {
    const url = `${api.url}/numbers`;
    const body = JSON.stringify(number.toRequest());
    await FetchService.post(url, body);
  }

  static async destroy(numberID: string) {
    const url = `${api.url}/numbers/${numberID}`;
    await FetchService.delete(url);
  }

  static async update(number: PhoneNumber) {
    const url = `${api.url}/numbers/${number.id}`;
    const body = JSON.stringify(number.toRequest());
    await FetchService.put(url, body);
  }

  static async listSDR(number: PhoneNumber): Promise<SDR[]> {
    const url = `${api.url}/numbers/${number.id}/sdrs`;
    const { results: responses } = await FetchService.get(url);
    const sdrs = responses.map(
      (response: Record<string, any>) => {
        return SDR.fromResponse(response);
      }
    )
    return sdrs;
  }
}

export default NumberService