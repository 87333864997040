import { DateTime } from "luxon";

interface Constructor {
  id?: string;
  name: string;
  date: DateTime
}

class Holiday {
  id?: string;
  name: string;
  date: DateTime;

  constructor(args: Constructor){
    this.id = args.id;
    this.name = args.name;
    this.date = args.date;
  }

  toRequest(){
    const jsonData = {
      id: this.id,
      name: this.name,
      date: this.date.toISODate()
    }
    return JSON.parse(JSON.stringify(jsonData));
  }

  static fromResponse(value: any){
    return new Holiday({
      id: value.id,
      name: value.name,
      date: DateTime.fromISO(value.date)
    })
  }
}
export default Holiday