import voltaIcons from "@vonagevolta/volta2/dist/symbol/volta-icons.svg";

import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  pointer: { cursor: "pointer" }
}), { index: 1 });

interface VoltaIconProps {
  icon: string;
  className?: any;
  onClick?: () => void;
}

function VoltaIcon({ icon, className, onClick }: VoltaIconProps) {
  const mStyles = useStyles();
  
  return (
    <svg 
      onClick={onClick}
      className={clsx(
        "Vlt-icon", 
        (onClick)? mStyles.pointer: "",
        className
      )}
    >
      <use xlinkHref={`${voltaIcons}#${icon}`} />
    </svg>
  )
}

export default VoltaIcon