import voltaIcons from "@vonagevolta/volta2/dist/symbol/volta-icons.svg";
import Button from "components/Button";

interface IAddButton {
  children?: any,
  onClick?: () => void;
  disabled?: boolean;
  isAdding?: boolean;
}

function AddButton({ children, onClick, disabled, isAdding }: IAddButton){
  return (
    <Button
      type="secondary"
      onClick={onClick}
      disabled={disabled}
      app
    >
      {
        isAdding? (
          <span className="Vlt-spinner Vlt-spinner--smaller Vlt-spinner--white" />
        ):(
          <svg>
            <use xlinkHref={`${voltaIcons}#Vlt-icon-plus`} />
          </svg>
        )
      }
      {children}
    </Button>
  )
}
export default AddButton;