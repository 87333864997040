import styled from "styled-components";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  zIndex: 999;
  background-color: white;
`;
export default LoginContainer;