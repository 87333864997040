import React from "react";

import Table from "components/Table";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

function DetailColumn({ sdr, setRefreshToken }){
  return (
    <Table.Column className="Vlt-table__cell--nowrap">
      <EditButton sdr={sdr} setRefreshToken={setRefreshToken} />
      <DeleteButton sdr={sdr} setRefreshToken={setRefreshToken} />
    </Table.Column>
  )
}
export default DetailColumn