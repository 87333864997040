import { createContext } from "react";
import { useState, useCallback } from "react";

import FetchService from "services/fetch";
import api from "configs/api";
import NumberService from "services/number";

import Route from "../entities/route";
import PhoneNumber from "entities/number";
import SDR from "entities/sdr";

interface CreateOptions {
  number: PhoneNumber;
  sdrs: SDR[];
}

interface DestroyOptions {
  number: PhoneNumber;
  sdrs: SDR[];
}

type RouteContextValues = {
  routes: Route[],
  fetch: () => Promise<void>,
  create: (args: CreateOptions) => Promise<void>,
  destroy: (args: DestroyOptions) => Promise<void>
}

interface RouteProviderProps {
  children: any;
}

export const RouteContext = createContext<RouteContextValues>({
  routes: [],
  fetch: () => Promise.resolve(),
  create: () => Promise.resolve(),
  destroy: () => Promise.resolve()
})

export default function RouteProvider({ children }: RouteProviderProps) {
  const [routes, setRoutes] = useState<Route[]>([]);

  const fetch = useCallback(
    async () => {
      const numbers = await NumberService.list();
      const routePromises = numbers.map(
        async (number) => {
          const sdrs = await NumberService.listSDR(number);
          return sdrs.map((sdr) => new Route({ number, sdr }));
        }
      );
      const routes = await Promise.all(routePromises);
      const flatRoutes = routes.flat();
      setRoutes(flatRoutes);
    },
    []
  );

  async function create({ number, sdrs }: CreateOptions){
    const url = `${api.url}/numbers/${number.id}/routes`;
    const body = JSON.stringify({ sdrs: sdrs.map((sdr) => sdr.id) });
    await FetchService.post(url, body);
    await fetch();
  }

  async function destroy({ number, sdrs }: DestroyOptions){
    const url = `${api.url}/numbers/${number.id}/routes`;
    const body = JSON.stringify({ sdrs: sdrs.map((sdr) => sdr.id) });
    await FetchService.delete(url, body);
    await fetch();
  }

  return (
    <RouteContext.Provider value={{
      routes,
      fetch,
      create,
      destroy
    }}>
      {children}
    </RouteContext.Provider>
  )
}
