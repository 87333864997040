import React from "react";
import useStyles from "./styles";

import ButtonIcon from "components/ButtonIcon";
import EditRouteModal from "../EditRouteModal";

function EditButton({ route }){
  const [ visible, setVisible ] = React.useState(false);
  const mStyles = useStyles();

  function handleClick(){
    setVisible(true);
  }

  return (
    <React.Fragment>
      <ButtonIcon 
        icon="Vlt-icon-edit"
        onClick={handleClick}
        className={mStyles.marginRight}
      />
      <EditRouteModal 
        route={route}
        visible={visible}
        setVisible={setVisible}
      
      />
    </React.Fragment>
  )
}
export default EditButton;