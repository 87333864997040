import PhoneNumber from "entities/number";
import SDR from "entities/sdr";

interface Constructor {
  number: PhoneNumber;
  sdr: SDR;
  sdrs?: SDR[];
}

class Route {
  number: PhoneNumber;
  sdr: SDR;
  sdrs?: SDR[];

  constructor(args: Constructor){
    this.number = args.number;
    this.sdr = args.sdr;
    this.sdrs = args.sdrs;
  }

  toJSON(){
    const jsonData = {
      number: this.number.toJSON(),
      sdr: this.sdr.toJSON()
    }
    return JSON.parse(JSON.stringify(jsonData));
  }
}
export default Route