class FetchHelper{
  static async fetch(func, stateFunc, args, callbacks){
    try{
      stateFunc(true);
      if(args) await func(args);
      else await func();
      if(callbacks?.finish) callbacks.finish();
    }catch(err){
      if(callbacks?.error) callbacks.error(err);
      console.log(err.stack);
    }finally{
      if(callbacks?.finally) callbacks.finally();
      stateFunc(false)
    }
  }
}
export default FetchHelper;