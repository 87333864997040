import React from "react";
import countryLookup from "country-code-lookup";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { makeStyles } from "@material-ui/styles";

import NumberIndicator from "components/NumberIndicator";
import FlagIcon from "components/FlagIcon";
import Table from "components/Table";

import DetailColumn from "./DetailColumn";

const useStyles = makeStyles(() => ({
  flagIcon: { marginRight: 8 }
}), { index: 1 })

function NormalTable({ sdrs, setRefreshToken, limit=10 }){
  const mStyles = useStyles();

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header />
          <Table.Header>NAME</Table.Header>
          <Table.Header>NUMBER</Table.Header>
          <Table.Header />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {sdrs.map((sdr, index) => {
          const phoneNumber = parsePhoneNumberFromString(`+${sdr.number}`);
          const country = countryLookup.byIso(phoneNumber.country ?? "AX");
          const countryName = country.country.toLowerCase().replace(/\s/, "-");
          const [ manager ] = sdr.managers;
          return (
            <Table.BodyRow key={sdr.id}>
              <Table.Column>
                <NumberIndicator number={(index + 1)} />
              </Table.Column>
              <Table.Column>
                <p><b>{sdr.name}</b></p>
                <p className="Vlt-grey">{sdr.email}</p>
                <p className="Vlt-grey">Manager: {(manager)? manager.name: "No Manager"}</p>
              </Table.Column>
              <Table.Column>
                <FlagIcon className={mStyles.flagIcon} icon={countryName} />
                {phoneNumber.formatInternational()}
              </Table.Column>
              <DetailColumn sdr={sdr} setRefreshToken={setRefreshToken} />
            </Table.BodyRow>
          )
        })}
      </Table.Body>
    </Table>
  )
}
export default NormalTable;