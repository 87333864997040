import { ChangeEvent, Dispatch, SetStateAction } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  container: { width: "100%" },
  select: { width: "100%" }
}), { index: 1 })

interface DropdownProps {
  label?: string;
  hint?: string;
  value: string;
  setValue?: Dispatch<SetStateAction<string>>;
  children: any;
  classes?: any;
  disabled?: boolean;
}

function Dropdown({ 
  label, 
  hint, 
  value, 
  setValue, 
  children, 
  classes,
  disabled=false 
}: DropdownProps) {
  const mStyles = useStyles();

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    if (setValue) setValue(e.target.value);
  }

  return (
    <div className="Vlt-form__element">
      <label className="Vlt-label">
        {label}
      </label>
      <div className={clsx(
        "Vlt-native-dropdown",
        "Vlt-native-dropdown--app",
        mStyles.container,
        classes?.container
      )}>
        <select
          className={clsx(
            mStyles.select,
            classes?.select
          )}
          value={value} 
          onChange={handleChange} 
          disabled={disabled}
        >
          {children}
        </select>
      </div>
      <small className="Vlt-form__element__hint">
        {hint}
      </small>
    </div>
  )
}
export default Dropdown;

